import consumer_car_order_carOrderDetails from '@/lib/data-service/haolv-default/consumer_car_order_carOrderDetails'
import consumer_car_order_carOrderDetailsForOrderCenter from '@/lib/data-service/haolv-default/consumer_car_order_carOrderDetailsForOrderCenter'
import orderLog from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/order-log/1.0.0/index.vue';
import moment from 'moment'
import _ from 'underscore';

export default {
    data () {
        return {
            activeName:'details',
            orderNo: '',
            type: 'detail',
            orderInfo: {
                orderCostResult:{},
                orderInfoResult:{},
                orderJourneyResult:{},
            },
        }
    },
    components: {
        orderLog,
    },
    created () {},
    mounted () {},
    activated () {
        this.orderNo = this.$route.query.orderNo;
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        } else {
            this.type = 'detail'
        }
        this.getDetail()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    // return consumer_log_hotelList({
                    //     orderNo: this.$route.query.id
                    // }).then(res => {
                    // 组装组件需要的list
                    let res = {
                        datas: [{"operator":"个人测试","operatorTime":"2021-12-06T15:54:09","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:01","logSource":"1","logContents":null},{"operator":"个人测试","operatorTime":"2021-12-06T15:55:57","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T15:56:08","logSource":"1","logContents":"创建订单成功，订单号：13877698977300"},{"operator":"个人测试","operatorTime":"2021-12-06T16:02:58","logSource":"1","logContents":"调用胤之旅接口，取消订单成功"}]
                    }
                    const list = _.chain(res.datas)
                        .map(function (m) {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        }).value()
                    const d = {
                        entityList: list
                    }
                    return d || {}
                    // }).catch(() => {
                    //     return []
                    // }).finally(() => {
                        this.loading = false
                    // })
                }
            })
        },
        handleClick(){
            this.getOrderLog()
        },
        clickSpan(){
            this.$router.push({
                name: 'admin-my-order-car',
            })
        },
        getDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let orderDetail = this.type === 'detail' ? consumer_car_order_carOrderDetails : consumer_car_order_carOrderDetailsForOrderCenter;
            orderDetail({orderNo: this.orderNo}).then(res => {
                loading.close();
                let orderInfo = res.datas;
                this.orderInfo = orderInfo;
            }).catch(() => {
                loading.close()
            })
        },
        back() {
            this.$router.back();
        },
        _setBudgetItemTypeName(val) {
            // 科目类型 1-管理 2-理赔 3-销售
            let text = '';
            switch (val) {
                case 1:
                    text = '管理';
                    break;
                case 2:
                    text = '理赔';
                    break;
                case 3:
                    text = '销售';
                    break;
                default:
                    text = '';
            }
            return text;
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
